/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import Image from "../components/Image";
import {graphql} from 'gatsby'
import Layout from "../components/layout"
import "./relaqua.css";
import {Button, Grid, Modal} from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import SEO from "../components/SEO";
import Contact from "../components/Contact";
import {Link} from 'gatsby'
import LanguageSwitcher from "../components/LanguageSwitcher";
import ReactCountryFlag from "react-country-flag";
import Drawer from "@material-ui/core/Drawer/Drawer";

import PlayIcon from '@material-ui/icons/PlayCircleFilled';


export default class Relaqua extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            video: 'https://www.youtube.com/embed/n_tDSYgI4yQ'
        };

    }

    showModal = () => {
        this.setState({
            visible: true,
            video: 'https://www.youtube.com/embed/n_tDSYgI4yQ'
        });
    };


    closeModal = e => {
        this.setState({
            visible: false,
            video: ''
        });

    };
    render() {
        return <Layout id="relaqua">
            <SEO title={this.props.data.wordpressPage.acf.seo_workaround.title}
                 description={this.props.data.wordpressPage.acf.seo_workaround.description}
            />
            <div className="relaqua-banner">
                <div className="image">
                    {this.props.data.wordpressPage.acf.banner_relaqua[0] && this.props.data.wordpressPage.acf.banner_relaqua[0].img &&
                    <Image image={this.props.data.wordpressPage.acf.banner_relaqua[0].img}/>
                    }
                </div>
                <div className="description">
                    <h1 className='title-big'>{this.props.data.wordpressPage.acf.banner_relaqua[0].text}</h1>
                    <Button variant="contained" color="secondary" onClick={this.showModal} size='large'>
                        <PlayIcon style={{marginRight: '0.5em'}}/>  Video
                    </Button>
                </div>
            </div>

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.visible}
                onClick={this.closeModal}
                onClose={this.closeModal}
            >
                <div>
                    <div className="closeIcon">
                        <Fab aria-label="close" onClick={this.closeModal} color="secondary">
                            X
                        </Fab>
                    </div>
                    <div className="video">
                        <div className="videoWrapper">
                            <Button color="danger" onClick={this.closeModal}>X</Button>
                            <iframe width="560"
                                    title="video"
                                    height="315"
                                    src={this.state.video}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                </div>
            </Modal>


            <Grid container className='relaqua-content-row' justify="space-around" alignContent="center">
                <Grid item lg={6} md={12} xs={12}>
                    <div className='relaqua-erlebe-content'>
                        <LanguageSwitcher defaultLanguage={this.props.pageContext.language}/>
                        <div>
                            <h3 className='title-big'>{this.props.data.wordpressPage.acf.relaqua_content_erlebe_title}!</h3>
                            <div>
                                {this.props.data.wordpressPage.acf.relaqua_content_erlebe_text}
                            </div>
                            <br/>
                        </div>
                        <div className='relaqua-erlebe-img'>
                            {this.props.data.wordpressPage.acf.relaqua_content_erlebe_img && <Image image={this.props.data.wordpressPage.acf.relaqua_content_erlebe_img}/>}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={0} md={0} lg={5}>
                    {this.props.data.wordpressPage.acf.relaqua_content_erlebe_img2 && <Image image={this.props.data.wordpressPage.acf.relaqua_content_erlebe_img2}/>}
                </Grid>
            </Grid>

            <Grid container className='relaqua-content-row' type="flex" justify="start">
                <Grid item lg={6} md={12} xs={12}>
                    <div className='relaqua-woher-image'>
                        {this.props.data.wordpressPage.acf.relaqua_content_woher_img && <Image className='relaqua-whoer-image-1' image={this.props.data.wordpressPage.acf.relaqua_content_woher_img}/>}
                        <br/>
                        {this.props.data.wordpressPage.acf.relaqua_content_woher_img2 && <Image className='relaqua-whoer-image-2' image={this.props.data.wordpressPage.acf.relaqua_content_woher_img2} />}
                    </div>
                </Grid>
                <Grid item lg={5} className='relaqua-woher-content'>
                    <h3 className='title-big'>{this.props.data.wordpressPage.acf.relaqua_content_woher_title}</h3>
                    <div>
                        {this.props.data.wordpressPage.acf.relaqua_content_woher_text}
                    </div>
                </Grid>
            </Grid>

            <Contact title={this.props.data.wordpressPage.acf.contact.title}
                     image={this.props.data.wordpressPage.acf.contact.image}
                     description={this.props.data.wordpressPage.acf.contact.description} />

            <div className='dark-hero-content'>
                <h2 className='title-big'>{this.props.data.wordpressPage.acf.relaqua_content_methode_title}</h2>
                <div>{this.props.data.wordpressPage.acf.relaqua_content_methode_text}</div>
            </div>

            {this.props.data.wordpressPage.acf.relaqua_content_methoden_page.map((method)=> {
                return <Grid container className='relaqua-content-row' type="flex" justify="start">
                    <Grid item lg={5} md={12} xs={12}>
                        <div className='relaqua-methode-image'>
                            {method.img && <Image image={method.img}/>}
                        </div>
                    </Grid>
                    <Grid item lg={5} md={12} xs={12} className='relaqua-methode-content'>
                        <h4 className='title-big'>{method.title}</h4>
                        <div>
                            {method.text}
                        </div>
                    </Grid>
                </Grid>
            })}
        </Layout>
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            title
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                seo_workaround {
                    title
                    description
                }
                contact {
                    title
                    description
                    image {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                relaqua_content_erlebe_text
                relaqua_content_erlebe_title
                relaqua_content_erlebe_img {
                    alt_text
                    title
                    description
                    localFile {
                        childImageSharp {
                            id
                            fluid {
                                base64
                                tracedSVG
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                }
                relaqua_content_erlebe_img2 {
                    alt_text
                    title
                    description
                    localFile {
                        childImageSharp {
                            id
                            fluid {
                                base64
                                tracedSVG
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                }
                relaqua_content_woher_text
                relaqua_content_woher_title
                relaqua_content_woher_img {
                    alt_text
                    title
                    description
                    localFile {
                        childImageSharp {
                            id
                            fluid {
                                base64
                                tracedSVG
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                }
                relaqua_content_woher_img2 {
                    alt_text
                    title
                    description
                    localFile {
                        childImageSharp {
                            id
                            fluid {
                                base64
                                tracedSVG
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                }
                relaqua_content_methode_text
                relaqua_content_methode_title
                banner_relaqua {
                    img {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                    text
                    button_text
                }
                relaqua_akademie_title
                relaqua_akademie_text
                relaqua_content_methoden_page {
                    title
                    text
                    img {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;


